import React from 'react';
import PropTypes from 'prop-types';
import {postedAt} from '../utils';

const DetailThreadCard = ({
  title,
  owner,
  date,
  body,
  totalComments,
  content,
  onContentChange,
  onAddComment,
  category,
  avatar,
}) => {
  return (
    <div className="card w-full max-w-xl bg-base-100 shadow-xl">
      <div className="card-body">
        <div className="flex gap-4 items-center mb-3">
          <img src={avatar} className="rounded-full" alt="Avatar" />
          <div>
            <h2 className="card-title">{title}</h2>
            <p className="text-sm">
              <span>{owner}</span> | <span>{postedAt(date)}</span>
            </p>
          </div>
        </div>
        <div dangerouslySetInnerHTML={{__html: body}}></div>
        <div className="flex gap-4 items-center">
          <div
            className="flex gap-2 items-center
        cursor-pointer mt-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75
            0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0
            01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375
            0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707
            3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14
            1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233
            2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394
            48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14
            2.25 6.741v6.018z"
              />
            </svg>
            <p className="font-light">{totalComments}</p>
          </div>
          <div
            className="flex gap-2 items-center
        cursor-pointer mt-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.568 3H5.25A2.25 2.25 0
              003 5.25v4.318c0 .597.237 1.17.659
              1.591l9.581 9.581c.699.699 1.78.872
              2.607.33a18.095 18.095 0
              005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16
              3.66A2.25 2.25 0 009.568 3z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 6h.008v.008H6V6z"
              />
            </svg>

            <p className="font-light">{category}</p>
          </div>
        </div>
      </div>
      <div className="card-body w-full">
        <textarea
          className="textarea textarea-bordered mb-2
          placeholder:text-slate-500"
          placeholder="Berikan balasan anda..."
          value={content}
          onChange={onContentChange}
        ></textarea>
        <button className="btn w-full" onClick={onAddComment}>
          Kirim
        </button>
      </div>
    </div>
  );
};

DetailThreadCard.propTypes = {
  title: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  totalComments: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  content: PropTypes.string,
  onContentChange: PropTypes.func,
  onAddComment: PropTypes.func,
  category: PropTypes.string,
  avatar: PropTypes.string,
};

export default DetailThreadCard;
