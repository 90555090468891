import api from '../../utils/api';
import {setIsLoadingActionCreator} from '../loading/action';

const ActionType = {
  RECEIVE_USERS: 'RECEIVE_USERS',
};

function receiveUsersActionCreator(users) {
  return {
    type: ActionType.RECEIVE_USERS,
    payload: {
      users,
    },
  };
}

function asyncRegisterUser({name, email, password}) {
  return async (dispatch) => {
    dispatch(setIsLoadingActionCreator(true));
    try {
      await api.register({name, email, password});
    } catch (error) {
      alert(error.message);
    }
    dispatch(setIsLoadingActionCreator(false));
  };
}

export {ActionType, receiveUsersActionCreator, asyncRegisterUser};

