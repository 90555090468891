/* eslint-disable require-jsdoc */
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Routes} from 'react-router-dom';
import Button from './components/Button';
import Loading from './components/Loading';
import Navbar from './components/Navbar';
import DetailThread from './pages/DetailThread';
import Home from './pages/Home';
import Login from './pages/Login';
import NewThread from './pages/NewThread';
import Register from './pages/Register';
import {asyncUnsetAuthUser} from './states/authUser/action';
import {asyncPreloadProcess} from './states/isPreload/action';

const App = () => {
  const {
    authUser = null,
    isPreload = false,
    isLoading = false,
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncPreloadProcess());
  }, [dispatch]);

  const onSignOut = () => {
    dispatch(asyncUnsetAuthUser());
  };

  if (isPreload) {
    return null;
  }

  if (authUser === null) {
    return (
      <div className="relative">
        <Navbar />
        {isLoading && <Loading />}
        <main className="w-full overflow-y-auto">
          <Routes>
            <Route path="/*" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Routes>
        </main>
      </div>
    );
  }

  return (
    <div className="relative">
      <Navbar onClick={onSignOut} />
      {isLoading && <Loading />}
      <main className="w-full overflow-y-auto">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/threads/:id" element={<DetailThread />} />
          <Route path="/new" element={<NewThread />} />
        </Routes>
      </main>
      <Button type="add" />
    </div>
  );
};

export default App;
