import React, {useEffect} from 'react';
import {IsEmpty, Map} from 'react-lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import CommentCard from '../components/CommentCard';
import DetailThreadCard from '../components/DetailThreadCard';
import useInput from '../hooks/useInput';
import {
  asyncAddComment,
  asyncReceiveThreadDetail,
} from '../states/threadDetail/action';

const DetailThread = () => {
  const {id} = useParams();
  const {threadDetail = null, authUser} = useSelector((state) => state);
  const [content, onContentChange, setContent] = useInput('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncReceiveThreadDetail(id));
  }, [id, dispatch]);

  if (!threadDetail) {
    return null;
  }

  const onAddComment = () => {
    dispatch(asyncAddComment({content, threadId: id}));
    setContent('');
  };

  return (
    <section className="flex flex-col items-center mt-20 mx-56 max-md:mx-5">
      <DetailThreadCard
        title={threadDetail.title}
        owner={threadDetail.owner.name}
        date={threadDetail.createdAt}
        body={threadDetail.body}
        totalComments={threadDetail.comments.length}
        content={content}
        onContentChange={onContentChange}
        onAddComment={onAddComment}
        category={threadDetail.category}
        avatar={authUser.avatar}
      />
      <h2 className="text-lg font-semibold mt-5">
        Komentar <span>({threadDetail.comments.length})</span>
      </h2>
      <div className="flex flex-col gap-3 w-full max-w-xl mb-10">
        <IsEmpty
          value={threadDetail.comments}
          yes={() => <p className="text-center mt-5">Tidak ada komentar</p>}
          no={() => (
            <Map
              collection={threadDetail.comments}
              iteratee={(comment) => (
                <CommentCard
                  key={comment.id}
                  owner={comment.owner.name}
                  date={comment.createdAt}
                  content={comment.content}
                />
              )}
            />
          )}
        />
      </div>
    </section>
  );
};

export default DetailThread;
