import api from '../../utils/api';
import {setIsLoadingActionCreator} from '../loading/action';
import {receiveThreadsActionCreator} from '../threads/action';
import {receiveUsersActionCreator} from '../users/action';

function asyncPopulateUsersAndThreads() {
  return async (dispatch) => {
    dispatch(setIsLoadingActionCreator(true));
    try {
      const users = await api.getAllUsers();
      const threads = await api.getAllThreads();

      dispatch(receiveUsersActionCreator(users));
      dispatch(receiveThreadsActionCreator(threads));
    } catch (error) {
      alert(error.message);
    }
    dispatch(setIsLoadingActionCreator(false));
  };
}


export {
  asyncPopulateUsersAndThreads,
};
