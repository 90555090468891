import React from 'react';

const Loading = () => {
  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 z-10">
      <div
        className="flex items-center justify-center
      bg-base-100/50 w-screen h-screen"
      >
        <img className="w-12 h-12" src="/loading-animation.gif" alt="Loading" />
      </div>
    </div>
  );
};

export default Loading;
