import React from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import LoginInput from '../components/LoginInput';
import {asyncSetAuthUser} from '../states/authUser/action';

const Login = () => {
  const dispatch = useDispatch();

  const onLogin = ({email, password}) => {
    dispatch(asyncSetAuthUser({email, password}));
  };
  return (
    <section className="flex flex-col items-center justify-center min-h-screen">
      <div className="mockup-window border bg-base-300 w-1/2 max-sm:w-5/6">
        <div className="px-4 py-16 bg-base-200">
          <LoginInput onLogin={onLogin} />
          <p className="text-center">
            Belum punya akun?{' '}
            <Link to="/register" className="underline">
              Daftar disini.
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Login;
