import React from 'react';
import PropTypes from 'prop-types';
import {postedAt} from '../utils';

const CommentCard = ({content, owner, date}) => {
  return (
    <div className="card w-full max-w-xl bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title">{owner}</h2>
        <p className="text-sm">
          <span>{postedAt(date)}</span>
        </p>
        <p dangerouslySetInnerHTML={{__html: content}}></p>
      </div>
    </div>
  );
};

CommentCard.propTypes = {
  content: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default CommentCard;
