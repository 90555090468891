import PropTypes from 'prop-types';
import React from 'react';
import useInput from '../hooks/useInput';

const LoginInput = ({onLogin}) => {
  const [email, onEmailChange] = useInput('');
  const [password, onPasswordChange] = useInput('');

  return (
    <div
      className="flex flex-col items-center justify-center
                      gap-5 mb-3"
    >
      <input
        type="text"
        placeholder="Enter your email"
        className="input input-bordered w-full max-w-xs"
        value={email}
        onChange={onEmailChange}
      />
      <input
        type="password"
        placeholder="Enter your password"
        className="input input-bordered w-full max-w-xs"
        value={password}
        onChange={onPasswordChange}
      />
      <button
        className="btn w-full max-w-xs"
        onClick={() => onLogin({email, password})}
      >
        Login
      </button>
    </div>
  );
};

LoginInput.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default LoginInput;
