import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

const Navbar = ({onClick}) => {
  return (
    <header
      className="navbar bg-base-100 z-10
    fixed top-0 left-0 right-0 border-b"
    >
      <div className="flex-1">
        <Link to="/" className="btn btn-ghost normal-case text-xl">
          Developer Forum App
        </Link>
      </div>
      <div className="navbar-end">
        {onClick ? (
          <button onClick={onClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0013.5
              3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25
              2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3
              0l3-3m0 0l-3-3m3 3H9"
              />
            </svg>
          </button>
        ) : (
          <Link className="btn" to="/login">
            Login
          </Link>
        )}
      </div>
    </header>
  );
};

Navbar.propTypes = {
  onClick: PropTypes.func,
};

export default Navbar;
