import PropTypes from 'prop-types';
import React from 'react';
import useInput from '../hooks/useInput';

const RegisterInput = ({onRegister}) => {
  const [name, onNameChange] = useInput('');
  const [email, onEmailChange] = useInput('');
  const [password, onPasswordChange] = useInput('');
  return (
    <div
      className="flex flex-col items-center justify-center px-4 py-16
        gap-5 bg-base-200"
    >
      <input
        type="text"
        placeholder="Enter your name"
        className="input input-bordered w-full max-w-xs"
        value={name}
        onChange={onNameChange}
      />
      <input
        type="text"
        placeholder="Enter your email"
        className="input input-bordered w-full max-w-xs"
        value={email}
        onChange={onEmailChange}
      />
      <input
        type="password"
        placeholder="Enter your password"
        className="input input-bordered w-full max-w-xs"
        value={password}
        onChange={onPasswordChange}
      />
      <button
        className="btn w-full max-w-xs"
        onClick={() => onRegister({name, email, password})}
      >
        Register
      </button>
    </div>
  );
};

RegisterInput.propTypes = {
  onRegister: PropTypes.func.isRequired,
};

export default RegisterInput;
