import React, {useEffect} from 'react';
import {IsEmpty, Map} from 'react-lodash';
import {useDispatch, useSelector} from 'react-redux';
import ThreadCard from '../components/ThreadCard';
import {asyncPopulateUsersAndThreads} from '../states/shared/action';

const Home = () => {
  const {threads = [], users = []} = useSelector((states) => states);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncPopulateUsersAndThreads());
  }, [dispatch]);

  const threadList = threads.map((thread) => ({
    ...thread,
    user: users.find((user) => user.id === thread.ownerId),
  }));

  return (
    <section className="mt-20 mx-56 max-md:mx-2">
      <h2 className="text-2xl font-bold text-center">Diskusi tersedia</h2>
      <div className="flex flex-col items-center gap-5 mb-10">
        <IsEmpty
          value={threadList}
          yes={() => <p className="text-center mt-5">Tidak ada komentar</p>}
          no={() => (
            <Map
              collection={threadList}
              iteratee={(thread) => (
                <ThreadCard
                  key={thread.id}
                  id={thread.id}
                  title={thread.title}
                  body={thread.body}
                  user={thread.user.name}
                  date={thread.createdAt}
                  totalComments={thread.totalComments}
                  category={thread.category}
                  avatar={thread.user.avatar}
                />
              )}
            />
          )}
        />
      </div>
    </section>
  );
};

export default Home;
