import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import RegisterInput from '../components/RegisterInput';
import {asyncRegisterUser} from '../states/users/action';

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onRegister = ({name, email, password}) => {
    dispatch(asyncRegisterUser({name, email, password}));
    navigate('/');
  };

  return (
    <section className="flex flex-col items-center justify-center min-h-screen">
      <div className="mockup-window border bg-base-300 w-1/2 max-sm:w-5/6">
        <RegisterInput onRegister={onRegister} />
      </div>
    </section>
  );
};

export default Register;
