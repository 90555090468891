import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

const Button = ({type}) => {
  if (type === 'add') {
    return (
      <Link
        to="/new"
        className="fixed bottom-5 right-5 bg-base-200
        p-3 rounded-full"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
      </Link>
    );
  }
  return <div>Button</div>;
};

Button.propTypes = {
  type: PropTypes.string,
};

export default Button;
