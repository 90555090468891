import api from '../../utils/api';
import {setAuthUserActionCreator} from '../authUser/action';
import {setIsLoadingActionCreator} from '../loading/action';

const ActionType = {
  SET_IS_PRELOAD: 'SET_IS_PRELOAD',
};

function setIsPreloadActionCreator(isPreload) {
  return {
    type: ActionType.SET_IS_PRELOAD,
    payload: {
      isPreload,
    },
  };
}

function asyncPreloadProcess() {
  return async (dispatch) => {
    dispatch(setIsLoadingActionCreator(true));
    try {
      // preload process
      const authUser = await api.getOwnProfile();
      dispatch(setAuthUserActionCreator(authUser));
    } catch (error) {
      // fallback process
      dispatch(setAuthUserActionCreator(null));
    } finally {
      // end preload process
      dispatch(setIsPreloadActionCreator(false));
    }
    dispatch(setIsLoadingActionCreator(false));
  };
}

export {ActionType, setIsPreloadActionCreator, asyncPreloadProcess};

