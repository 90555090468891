import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import useInput from '../hooks/useInput';
import {asyncAddThread} from '../states/threads/action';

const NewThread = () => {
  const [title, onTitleChange] = useInput('');
  const [category, onCategoryChange] = useInput('');
  const [body, setBody] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onBodyChange = (event) => {
    const text = event.target.innerHTML;
    setBody(text);
  };

  const onAddComment = () => {
    dispatch(asyncAddThread({title, category, body}));
    navigate('/');
  };

  return (
    <section className="flex flex-col items-center justify-center min-h-screen">
      <div className="mockup-window border bg-base-300 w-2/4 max-sm:w-5/6">
        <div
          className="flex flex-col items-center justify-center pb-16
          pt-10 gap-5 bg-base-200"
        >
          <h2 className="font-semibold text-xl">Buat Diskusi Baru</h2>
          <input
            type="text"
            placeholder="Enter your title"
            className="input input-bordered w-full max-w-md
            max-sm:max-w-[18rem]"
            value={title}
            onChange={onTitleChange}
          />
          <input
            type="text"
            placeholder="Enter your category"
            className="input input-bordered w-full max-w-md
            max-sm:max-w-[18rem]"
            value={category}
            onChange={onCategoryChange}
          />
          <div
            className="input input-bordered w-full max-w-md text-md
              h-fit p-4 max-sm:max-w-[18rem]"
            contentEditable="true"
            spellCheck="false"
            onInput={onBodyChange}
          ></div>
          <button
            className="btn w-full max-w-md max-sm:max-w-[18rem]"
            onClick={() => onAddComment()}
          >
            Buat
          </button>
        </div>
      </div>
    </section>
  );
};

export default NewThread;
